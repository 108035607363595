import propsToDom from 'core/helpers/propsToDom';
import propsToLink from 'core/helpers/propsToLink';
import React, { useCallback } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import styled from 'styled-components';
import { font20, font45 } from 'styles/typography';
import Close from './Close';
import Grid, { mainGrid } from './Grid';
import Icon from './Icon';
import Link from './Link';
import RichText from './RichText';
import { motion, AnimatePresence } from 'framer-motion';
import beziers from 'core/beziers';

const Menu = ({ location, ...others }) => {
  const { isMenuOpen, setMenuOpen } = useGlobalContext();
  const { url, headerData } = usePageContext();
  const { menu, mobile_menu_footer } = headerData;
  const { scrollView } = useGlobalContext();

  const close = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const handleAnchor = (event, anchor) => {
    if (location.pathname === '/') {
      event.preventDefault();
      event.stopPropagation();
      const $element = scrollView.querySelector(`#${anchor}`);
      if ($element) {
        const top = $element.offsetTop;
        scrollView.scrollTo(0, top);
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      {isMenuOpen && (
        <motion.div
          key="menu"
          initial={{ x: '100%' }}
          animate={{ x: '0%' }}
          exit={{ x: '100%' }}
          transition={{
            type: 'tween',
            duration: 1,
            ease: beziers.easeOutExpo,
          }}
          {...propsToDom(others)}
        >
          <Close onClick={close}>
            <Icon id="close" />
          </Close>
          <Nav>
            {menu.map(({ anchor, label, link }) => {
              const hasLink = !!link?.url;
              const key = hasLink ? link.url + label : anchor + label;
              const isCurrentPageLink = url == link.url;

              return (
                <NavItem
                  key={key}
                  {...propsToLink(link)}
                  onClick={(event) => {
                    if (anchor) handleAnchor(event, anchor);
                    close();
                  }}
                  isCurrent={isCurrentPageLink}
                  exit={{
                    length: 0.1,
                  }}
                  entry={{
                    delay: 0.1,
                    state: { anchor },
                  }}
                >
                  {label}
                </NavItem>
              );
            })}

            <RichText field={mobile_menu_footer.richText} />
          </Nav>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Nav = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-column: 2 / span 22;
  padding-top: ${({ theme }) => theme.spacing.s9};
  padding-bottom: ${({ theme }) => theme.spacing.s3};

  ${RichText} {
    ${font20};
    margin-top: auto;
  }
`;

const NavItem = styled(Link)`
  ${font45};
`;

export default styled(Menu)`
  ${mainGrid};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.turquoise};
  color: ${({ theme }) => theme.colors.blueDark};

  ${Close} {
    position: absolute;
    top: ${({ theme }) => theme.spacing.s2};
    right: ${(1 / 24) * 100}%;
    background: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.white};
  }
`;
