import range from 'core/helpers/range';
import { Graphics, Point, Sprite } from 'pixi.js';
import Scene from './AbstractScene';

class SelectedWorks extends Scene {
  constructor({ width, height, rotation, x, y, opacity, originX, data }) {
    super();

    const maskPivot = new Point(0, 0);
    const mask = new Graphics();
    mask.pivot = maskPivot;

    const spritePivot = new Point(1920 / 2, 1080 / 2);
    const sprites = data.map(({ uid, img }) => {
      const sprite = Sprite.from(img);
      sprite.uid = uid;
      sprite.mask = mask;
      sprite.visible = false;
      sprite.width = 1920;
      sprite.height = 1080;
      sprite.pivot = spritePivot;
      sprite.x = window.innerWidth / 2;
      sprite.y = window.innerHeight / 2;
      this.container.addChild(sprite);
      return sprite;
    });

    this.container.addChild(mask);
    this.container.zIndex = 2;

    this.width = width;
    this.height = height;
    this.rotation = rotation;
    this.x = x;
    this.y = y;
    this.opacity = opacity;
    this.originX = originX;
    this.maskPivot = maskPivot;
    this.spritePivot = spritePivot;

    this.mask = mask;
    this.sprites = sprites;
  }

  bind() {
    super.bind();
    this.render = this.render.bind(this);
    this.setActiveUid = this.setActiveUid.bind(this);
  }

  setActiveUid(uid) {
    const { sprites } = this;
    sprites.forEach((sprite) => (sprite.visible = sprite.uid === uid));
    this.activeSprite = sprites.find((sprite) => sprite.visible === true);
  }

  render() {
    const { activeSprite, mask, maskPivot, x, y, rotation, width, height, opacity, originX } = this;

    maskPivot.set(width.get() / 2, height.get() / 2);

    mask.clear();
    mask.beginFill(0x00ff00);
    mask.drawRect(0, 0, width.get(), height.get());
    mask.endFill();
    mask.pivot = new Point(width.get() / 2, height.get() / 2);
    mask.position = new Point(x.get(), y.get());
    mask.rotation = rotation.get();

    if (activeSprite) {
      const width = mask.height * (1920 / 1080);
      const minPivotX = ((1080 / mask.height) * mask.width) / 2;
      const maxPivotX = 1920 - minPivotX;
      activeSprite.rotation = mask.rotation;
      activeSprite.x = mask.x;
      activeSprite.y = mask.y;
      activeSprite.pivot.x = range(originX.get() * 1920, 0, 1920, minPivotX, maxPivotX);
      activeSprite.height = mask.height;
      activeSprite.width = width;
      activeSprite.alpha = opacity.get();
    }
  }
}

export default SelectedWorks;
