import React, { createContext, useContext } from 'react';

export const HeaderContext = createContext({});

const HeaderProvider = ({ children }) => {
  return <HeaderContext.Provider value={{}}>{children}</HeaderContext.Provider>;
};

export default HeaderProvider;

export const useHeaderContext = () => {
  const context = useContext(HeaderContext);
  if (!context) {
    throw Error('useHeaderContext must be used inside a `HeaderProvider`');
  }
  return context;
};
