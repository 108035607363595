import { createGlobalStyle } from 'styled-components';
import media from 'styles/media';
import { rem } from 'polished';
import theme from 'styles/theme';

const defaultFluidPx = rem('1px');
const minFluidPx = rem('0.8px');
const maxFluidPx = rem('1.25px');
const vpFluidPx = `${(1 / theme.designedDesktop) * 100}vw`;

const GlobalStyle = createGlobalStyle`
  :root {
    --vw: ${({ vwUnit }) => `${vwUnit}px` || '1vw'};
    --fluidPx: max(${minFluidPx}, ${vpFluidPx});
    --headerHeight: ${({ theme }) => theme.headerHeight};

    ${media.xxlarge`
      --fluidPx: ${maxFluidPx};
    `}

    ${media.tabletPortrait`
      --fluidPx: ${defaultFluidPx};
    `}
  }

  html {
    font-size: 100%;
    line-height: ${({ theme }) => theme.lineHeight.base};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.font};
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    width: 100%;
    height: 100%;
    overscroll-behavior: none;
  }

  body {
    font-size: ${({ theme }) => theme.fontSize.normal};
    overscroll-behavior: none;
    width: 100%;
    height: 100%;
  }

  .scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

export default GlobalStyle;
