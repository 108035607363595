import React from 'react';
import Mono from './slices/Mono';
import Duo from './slices/Duo';
import Carousel from './slices/Carousel';
import Text from './slices/Text';

export function getSlicesArray({ slices = [] }) {
  return slices.map((s) => {
    switch (s.slice_type) {
      case 'text':
        return <Text key={s.id} {...s} />;
      case 'mono':
        return <Mono key={s.id} {...s} />;
      case 'duo':
        return <Duo key={s.id} {...s} />;
      case 'carousel':
        return <Carousel key={s.id} {...s} />;
      default:
        return null;
    }
  });
}

const SliceZone = (props) => {
  return getSlicesArray(props);
};

export default SliceZone;
