import React from 'react';
import styled from 'styled-components';
import ArrowRight from '-!svg-react-loader!../assets/svgs/arrow-right.svg';
import Close from '-!svg-react-loader!../assets/svgs/close.svg';
import Logo from '-!svg-react-loader!../assets/svgs/logo.svg';

const Icon = ({ id, ...others }) => {
  switch (id) {
    case 'arrow-right':
      return <ArrowRight data-id={id} {...others} />;
    case 'close':
      return <Close data-id={id} {...others} />;
    case 'logo':
      return <Logo data-id={id} {...others} />;
    default:
      return null;
  }
};

export default styled(Icon)`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: top;

  &[data-id='logo'] {
    width: ${2216 / 2192}em;
  }
`;
