import React from 'react';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import ImgixOrSVG from './ImgixOrSVG';
import { motion, useTransform } from 'framer-motion';
import { molasses } from 'constants/springs';
import { useMeasure } from 'react-use';
import { sizeAsColPercent } from 'styles/utils';
import media from 'styles/media';

const HeroProject = ({ cover, logo, ...others }) => {
  const hasCover = !!cover?.url;
  const hasLogo = !!logo?.url;
  const { scrollY } = useGlobalContext();
  const { transitionStatus: status } = useTransitionState();
  const [$element, { height }] = useMeasure();

  const scale = useTransform(scrollY, (y) => Math.max(1 - (1 / 24) * 3 * (y / 500)));
  const y = useTransform(scrollY, (y) => y * 0.6);
  const yLogo = useTransform(scrollY, [0, height], [0, height]);

  const overlayVariants = {
    exited: { opacity: 0 },
    entering: { opacity: 0 },
    entered: { opacity: 1 },
  };

  const logoVariants = {
    exited: {
      y: 60,
      opacity: 0,
    },
    entering: {
      y: 60,
      opacity: 0,
    },
    entered: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        ...molasses,
      },
    },
  };

  return (
    <div {...others} ref={$element}>
      {hasCover && (
        <Cover
          style={{
            scale,
            y,
          }}
        >
          <img src={cover.url} role="presentation" />
          <Overlay
            initial={overlayVariants.exited}
            animate={overlayVariants[status]}
            transition={{ duration: 0.5 }}
          />
        </Cover>
      )}
      {hasLogo && (
        <Logo>
          <motion.div
            initial={logoVariants.exited}
            animate={logoVariants[status]}
            transition={{ duration: 1 }}
          >
            <ImgixOrSVG {...logo} style={{ y: yLogo }} loading="eager" />
          </motion.div>
        </Logo>
      )}
    </div>
  );
};

const Cover = styled(motion.div)`
  position: relative;
  height: 100%;

  img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  width: 100vw;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: ${({ theme }) => theme.colors.overlayHeroProject};
`;

const Logo = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin: auto;
  transform: translateY(-50%);
  z-index: 2;
  width: ${sizeAsColPercent(14)};
  align-self: center;

  ${media.mobile`
    width: ${sizeAsColPercent(20)};
  `}
`;

export default styled(HeroProject)`
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: var(--hero-background-color, ${({ theme }) => theme.colors.black});
`;
