import React, { forwardRef, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import imgixUrl from 'core/helpers/imgixUrl';
import { em, rem } from 'polished';
import { sizeAsColVw } from 'styles/utils';
import { motion } from 'framer-motion';

const defaultFluid = [640, 1024, 1280, 1440, 1920, 2560];
const hasNativeLazyLoadSupport =
  typeof HTMLImageElement !== `undefined` && `loading` in HTMLImageElement.prototype;

// Method to creates sizes attribute for <img /> including
// design contrainst (max content width + responsive)
export function getSizesAttr(sizes) {
  if (sizes instanceof Array === false) sizes = [sizes];

  return sizes
    .sort((a, b) => (!a.bp ? 1 : -1))
    .map(({ bp = false, cols }) => {
      if (bp) {
        return `(max-width: ${em(bp)}) ${sizeAsColVw(cols)}`;
      } else {
        return sizeAsColVw(cols);
      }
    })
    .join(', ');
}

const ImgixOrSVGComponent = (
  {
    url,
    alt,
    dimensions = {},
    isNativeDimensions = false,
    cover = false,
    fluid = [...defaultFluid],
    loading = 'lazy',
    onlyNativeLazy = false,
    hasLegend = false,
    legendColor = 'white',
    sizes = '100vw',
    role = null,
    onLoad = null,
    ...others
  },
  ref
) => {
  const observer = useRef(null);
  const $img = useRef(null);
  const [isSet, setIsSet] = useState(onlyNativeLazy);
  const [key, setKey] = useState('server');
  const allowSrcAttr = isSet || hasNativeLazyLoadSupport;

  useEffect(() => {
    const shouldUseIntersectionObs = key === 'client' && $img.current && !hasNativeLazyLoadSupport;

    if (shouldUseIntersectionObs && loading === 'eager') {
      setIsSet(true);
    } else if (shouldUseIntersectionObs) {
      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            setIsSet(true);
            observer.current.disconnect();
          }
        },
        {
          rootMargin: '200px',
          threshold: 0,
        }
      );

      observer.current.observe($img.current);
    }
  }, [key, loading]);

  useEffect(() => {
    setKey('client');
  }, []);

  // ------------------------------------------------------------
  //    RENDERING
  // ------------------------------------------------------------
  if (!dimensions || !url) return;

  const { width, height } = isNativeDimensions
    ? { width: dimensions.width / 2, height: dimensions.height / 2 }
    : dimensions;
  const dimensions2x = { width: width * 2, height: height * 2 };
  const ratio = width / height;
  const rawUrl = new URL(url);
  const [ext] = rawUrl.pathname.match(/\.[0-9a-z]+$/i);
  const isSVG = ext.toLowerCase() === '.svg';

  if (typeof sizes === 'number') {
    sizes = `${sizes}vw`;
  }

  const attrs = {
    width: dimensions.width,
    height: dimensions.height,
    loading,
    role,
    alt,
    src: allowSrcAttr
      ? url
      : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
  };

  // Three types of images here:
  // - SVG, just return the image
  // - Fluid format is using srcSet `w` operand to get multiples sources
  // - Fixed format is using srcSet `x` operand to get retina images
  if (isSVG) {
    // Nothing to do
  } else if (fluid instanceof Array) {
    // Get minimal width between sizesList & image natural width
    const max = Math.min(dimensions2x.width, Math.max(...fluid));

    // Add 1x & 2x in list
    fluid.push(dimensions.width);
    fluid.push(dimensions2x.width);

    // Create srcSet
    const srcSet = fluid
      .sort((a, b) => a - b)
      .filter((v) => v <= max)
      .map((v) => {
        return `${imgixUrl(url, { w: v, h: Math.round(v / ratio) })} ${v}w`;
      });

    attrs[allowSrcAttr ? 'srcSet' : 'data-srcset'] = srcSet.join(',');
    attrs.sizes = sizes;
  } else {
    // Build srcSet with 1x & 2x
    const srcSet = [dimensions, dimensions2x].map(
      ({ width, height }, index) => `${imgixUrl(url, { w: width, h: height })} ${index + 1}x`
    );

    attrs[allowSrcAttr ? 'srcSet' : 'data-srcset'] = srcSet.join(',');
  }

  return (
    <motion.picture ref={ref} key={key} {...others}>
      {!cover && <span style={{ paddingBottom: (1 / ratio) * 100 + '%' }} />}
      <img ref={$img} {...attrs} onLoad={allowSrcAttr ? onLoad : null} />
      {hasLegend && <Legend textColor={legendColor}>{alt}</Legend>}
    </motion.picture>
  );
};

export const Legend = styled.div`
  position: absolute;
  left: ${sizeAsColVw(1)};
  right: ${sizeAsColVw(1)};
  bottom: ${sizeAsColVw(1)};
  color: ${({ theme, textColor }) => theme.colors[textColor === 'white' ? 'altFont' : 'font']};
  text-shadow: 0 0
    ${({ theme, textColor }) =>
      `${rem('10px')} ${theme.colors[textColor === 'white' ? 'shadowLegend' : 'shadowLegendAlt']}`};
`;

const ImgixOrSVG = styled(forwardRef(ImgixOrSVGComponent))`
  position: relative;
  display: block;

  & > span {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default ImgixOrSVG;
