import { useState } from 'react';
import { useEffectOnce } from 'react-use';

const useFontsLoaded = () => {
  const [isFontLoaded, setFontLoaded] = useState(false);

  useEffectOnce(() => {
    document.fonts.ready.then(() => {
      if (setFontLoaded instanceof Function) {
        setFontLoaded(true);
      }
    });
  });

  return isFontLoaded;
};

export default useFontsLoaded;
