import styled, { css } from 'styled-components';
import { fluidPx } from './utils';
// import media from './media';

// ------------------------------------------------------------
//    TITLES
// ------------------------------------------------------------

export const font20 = css`
  font-size: ${fluidPx(20)};
  line-height: ${30 / 20};
`;

export const font28 = css`
  font-size: ${fluidPx(28)};
  line-height: ${40 / 30};
`;

export const font30 = css`
  font-size: ${fluidPx(30)};
  line-height: ${40 / 30};
`;

export const font36 = css`
  font-size: ${fluidPx(36)};
  line-height: ${40 / 30};
`;

export const font50 = css`
  font-size: ${fluidPx(50)};
  line-height: ${70 / 50};
`;

export const font45 = css`
  font-size: ${fluidPx(45)};
  line-height: ${49 / 45};
`;

export const font60 = css`
  font-size: ${fluidPx(60)};
  line-height: ${70 / 60};
`;

export const font70 = css`
  font-size: ${fluidPx(70)};
  line-height: 1.2;
`;

export const font90 = css`
  font-size: ${fluidPx(90)};
  line-height: ${100 / 90};
`;

export const Title2 = styled.h2`
  ${font20};
`;
