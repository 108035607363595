import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SliceZone from 'components/SliceZone';
import SEO from 'components/SEO';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Page = withPrismicPreview(({ data, pageContext }) => {
  if (!data?.prismicPage?.data) return null;

  const { title, body, ...others } = data.prismicPage.data;

  return (
    <Module>
      <SEO {...others} />
      <SliceZone allSlices={body} />
    </Module>
  );
});

const Module = styled.div``;

export default Page;

export const pageQuery = graphql`
  query PageQuery($uid: String!, $lang: String!) {
    prismicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        seo_og_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        schemaorg {
          text
        }
        title {
          text
        }
      }
    }
  }
`;
