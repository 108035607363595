import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import propsToDom from 'core/helpers/propsToDom';
import { useGlobalContext } from 'store/GlobalProvider';
import RichText from './RichText';
import Splitting from 'splitting';
import useFontsLoaded from 'hooks/useFontsLoaded';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const AppearingRichText = ({ field, children, ...others }) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const $element = useRef(null);
  const $text = useRef(null);

  // ------------------------------------------------------------
  //    STATE
  // ------------------------------------------------------------
  const [interactive, setInteractive] = useState(false);

  // ------------------------------------------------------------
  //    HOOKS
  // ------------------------------------------------------------
  const { scrollView } = useGlobalContext();
  const { mount } = useTransitionState();
  const fontsLoaded = useFontsLoaded();
  const isIntersecting = useIntersectionObserver($element, {
    rootMargin: '-40% 0px -40% 0px',
    root: scrollView,
    threshold: 0.0,
    once: true,
  });

  // ------------------------------------------------------------
  //    EFFECTS
  // ------------------------------------------------------------
  const handleAppearEnd = () => {
    setInteractive(true);
  };

  useEffect(() => {
    const isSplitted = $text.current.classList.contains('splitting');

    if (mount && fontsLoaded && isIntersecting && !isSplitted) {
      Splitting({
        target: $text.current,
        by: 'lines',
      });

      const $last = $text.current.querySelector('.word:last-child');
      if ($last) $last.addEventListener('animationend', handleAppearEnd);

      return () => {
        if ($last) $last.removeEventListener('animationend', handleAppearEnd);
      };
    }
  }, [isIntersecting, fontsLoaded, mount]);

  // ------------------------------------------------------------
  //    RENDERING
  // ------------------------------------------------------------
  return (
    <div ref={$element} {...propsToDom(others)} data-interactive={interactive}>
      <RichText ref={$text} field={field} />
    </div>
  );
};

export default styled(AppearingRichText)`
  ${RichText} {
    visibility: hidden;

    a {
      pointer-events: none;
    }
  }

  &[data-interactive='true'] ${RichText} a {
    pointer-events: auto;
  }

  .splitting {
    visibility: visible;

    .word {
      position: relative;
      animation: slide-in 1s ${({ theme }) => theme.easings.easeOutCirc} both;
      animation-delay: calc(15ms * (var(--word-index) - 1));
    }
  }

  @keyframes slide-in {
    from {
      clip-path: inset(0 -1em 150% -1em);
      transform: translate3d(0, 100%, 0);
    }
    to {
      clip-path: inset(0 -1em 0% -1em);
      transform: translate3d(0, 0%, 0);
    }
  }
`;
