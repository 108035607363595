import React from 'react';
import styled from 'styled-components';
import propsToDom from 'core/helpers/propsToDom';
import { usePageContext } from 'store/PageProvider';
import { mainGrid } from './Grid';
import Link from './Link';
import { font50 } from 'styles/typography';
import { fluidPx } from 'styles/utils';
import { useMeasure, useScrollbarWidth } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import { motion, useTransform } from 'framer-motion';
import propsToLink from 'core/helpers/propsToLink';
import media from 'styles/media';
import { buttonReset } from 'styles/others';
import Icon from './Icon';

const HeaderComponent = ({ location, ...others }) => {
  // ------------------------------------------------------------
  //    STATE
  // ------------------------------------------------------------
  const { url, pageType, headerData } = usePageContext();
  const { scrollY, scrollView, setMenuOpen } = useGlobalContext();

  // ------------------------------------------------------------
  //    HOOKS
  // ------------------------------------------------------------
  const sbw = useScrollbarWidth();
  const [measure, { height }] = useMeasure();
  const y = useTransform(scrollY, [0, height], [0, -height]);

  // ------------------------------------------------------------
  //    RENDERING
  // ------------------------------------------------------------
  const { title_logo, menu } = headerData;

  const handleAnchor = (event, anchor) => {
    if (location.pathname === '/') {
      event.preventDefault();
      event.stopPropagation();
      const $element = scrollView.querySelector(`#${anchor}`);
      if ($element?.parentNode) {
        const top = $element.parentNode.offsetTop;
        scrollView.scrollTo(0, top);
      }
    }
  };

  return (
    <motion.header
      ref={measure}
      {...propsToDom(others)}
      style={{ y, right: sbw }}
      transformTemplate={({ y }) => `translateY(${y})`}
    >
      <Content>
        <Logo to="/" aria-label={title_logo.text}>
          <Icon id="logo" />
        </Logo>
        <Nav>
          {menu
            .filter(({ link }) => {
              const isAboutPage = pageType === 'page_about';
              const isCurrentPageLink = url == link.url;
              return isAboutPage && isCurrentPageLink ? false : true;
            })
            .map(({ anchor, label, link }) => {
              const hasLink = !!link?.url;
              const key = hasLink ? link.url + label : anchor + label;

              return (
                <Link
                  key={key}
                  {...propsToLink(link)}
                  onClick={anchor ? (event) => handleAnchor(event, anchor) : null}
                  exit={{
                    length: 0.1,
                  }}
                  entry={{
                    delay: 0.1,
                    state: { anchor },
                  }}
                >
                  {label}
                </Link>
              );
            })}
        </Nav>
        <MenuToggle onClick={() => setMenuOpen(true)} />
      </Content>
    </motion.header>
  );
};

const Content = styled.div`
  position: relative;
  padding: ${fluidPx(40)} 0;
  grid-column: 2 / span 22;
  display: flex;
  align-items: center;

  ${media.mobile`
    padding: ${fluidPx(20)} 0;
  `}
`;

const Logo = styled(Link)`
  ${font50};
  ${Icon} {
    display: block;
  }
`;

const Nav = styled.nav`
  margin-left: auto;

  ${Link} {
    margin-left: ${fluidPx(30)};
  }

  ${media.mobile`
    display: none;
  `}
`;

const MenuToggle = styled.button`
  ${buttonReset};
  display: none;
  width: ${({ theme }) => theme.spacing.s2};
  height: ${({ theme }) => theme.spacing.s2};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  margin-left: auto;

  ${media.mobile`
    display: block;
  `}
`;

export default styled(HeaderComponent)`
  ${mainGrid};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }
`;
