import React, { useCallback } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useGlobalContext } from 'store/GlobalProvider';
import { fluidPx, sizeAsColPercent } from 'styles/utils';
import { dry } from 'constants/springs';
import Icon from './Icon';
import Scrollable from './Scrollable';
import Close from './Close';

const VARIANTS_CONTAINER = {
  visible: {
    x: '0%',
    opacity: 1,
    transition: { ...dry, when: 'beforeChildren', staggerChildren: 0.15 },
  },
  hidden: {
    x: '15%',
    opacity: 0,
  },
  exit: {
    x: '15%',
    opacity: 0,
    transition: { ...dry, when: 'afterChildren' },
  },
};

const Pane = ({ uid, children, onScrollableMount, ...others }) => {
  const { setPaneId } = useGlobalContext();

  const close = useCallback(() => {
    setPaneId(null);
  }, [setPaneId]);

  return (
    <div {...others}>
      <Overlay onClick={close} />
      <Container variants={VARIANTS_CONTAINER} initial="hidden" animate="visible" exit="exit">
        <Close onClick={close}>
          <Icon id="close" />
        </Close>
        <Scrollable onMount={onScrollableMount}>
          <Content>{children}</Content>
        </Scrollable>
      </Container>
    </div>
  );
};

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${sizeAsColPercent(20)};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.turquoise};
  color: ${({ theme }) => theme.colors.blueDark};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);

  ${Scrollable} {
    width: 100%;
    height: 100%;
  }

  ${Close} {
    position: absolute;
    right: ${fluidPx(60)};
    top: ${fluidPx(40)};
  }
`;

const Content = styled(motion.div)``;

export default styled(Pane)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.header};
`;
