import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from 'components/SEO';
import RichText from 'components/RichText';
import Page from 'components/Page';
import { mainGrid } from 'components/Grid';
import { font30 } from 'styles/typography';
import SelectedWorks from 'components/SelectedWorks';
import HomeGradientScene from 'webgl/scenes/HomeGradient';

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import AppearingRichText from 'components/AppearingRichText';
import { useGlobalContext } from 'store/GlobalProvider';
import { usePageContext } from 'store/PageProvider';
import media from 'styles/media';
import WebglManager from 'webgl/WebglManager';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { fluidPx } from 'styles/utils';

const PageHome = withPrismicPreview(({ data, location }) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const $canvas = useRef(null);

  // ------------------------------------------------------------
  //    HOOKS
  // ------------------------------------------------------------
  const { webgl, setWebgl } = useGlobalContext();
  const { siteData } = usePageContext();

  useEffect(() => {
    if ($canvas.current) {
      const webgl = new WebglManager({
        $element: $canvas.current,
      });

      setWebgl(webgl);

      return webgl.destroy;
    }
  }, [setWebgl]);

  useEffect(() => {
    if (webgl) {
      const scene = new HomeGradientScene();

      scene.connect(webgl.app);

      return () => {
        scene.disconnect(webgl.app);
        scene.destroy();
      };
    }
  }, [webgl]);

  if (!data?.prismicPageHome?.data) return null;

  const { title, introduction, work_title, ...others } = data.prismicPageHome.data;

  const works = siteData.works
    .filter(({ work }) => {
      const hasLink = !!work?.document?.url;
      const hasTitle = !!work?.document?.data?.title?.text;
      return hasLink && hasTitle;
    })
    .map(({ work }) => work.document);

  return (
    <Module>
      <SEO {...others} />
      <Canvas ref={$canvas} />
      <Page location={location}>
        <Introduction field={introduction.richText} />
        <SelectedWorks id="work" title={work_title} works={works} />
      </Page>
    </Module>
  );
});

const Canvas = styled.canvas`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0f0076;
`;

const Module = styled.section`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.white};
`;

const Introduction = styled(AppearingRichText)`
  ${mainGrid};
  padding: ${({ theme }) => theme.spacing.s12} 0;
  min-height: 100vh;
  align-items: center;

  ${RichText} {
    font-size: ${fluidPx(68)};
    font-weight: 300;
    line-height: 1;
    grid-column: 3 / span 20;
  }

  ${media.mobile`
    ${RichText} {
      ${font30};
      grid-column: 2 / -2;
    }
  `}
`;

export default PageHome;

export const pageQuery = graphql`
  query PageHomeQuery($uid: String!, $lang: String!) {
    prismicPageHome(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        seo_og_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        schemaorg {
          text
        }
        title {
          text
        }
        introduction {
          richText
        }
        work_title {
          text
        }
      }
    }
  }
`;
