import { useEffect, useRef, useState } from 'react';

const useBounds = () => {
  const ref = useRef();
  const [bounds, setBounds] = useState();

  const refresh = () => {
    if (ref.current) {
      setBounds(ref.current.getBoundingClientRect());
    }
  };

  useEffect(() => {
    const handle = () => {
      setBounds(ref.current.getBoundingClientRect());
    };

    const $element = ref.current;
    const observer = new ResizeObserver(handle);

    observer.observe($element);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [ref, bounds, refresh];
};

export default useBounds;
