import { Filter, Graphics } from 'pixi.js';
import Scene from './AbstractScene';
import frag from 'webgl/shaders/gradient.frag';

const rgb = (r, g, b) => {
  return [r / 255, g / 255, b / 255];
};

function mixChannel(A, B, ratio) {
  return Math.round((B - A) * ratio + A);
}

function blend(color1, color2, ratio, destination = {}) {
  destination.r = mixChannel(color1.r, color2.r, ratio);
  destination.g = mixChannel(color1.g, color2.g, ratio);
  destination.b = mixChannel(color1.b, color2.b, ratio);
  destination.a = 1;

  return destination;
}

// const color1 = { r: 75, g: 0, b: 233 };
// const color2 = { r: 100, g: 17, b: 232 };
// const color3 = { r: 133, g: 39, b: 231 };
// const color4 = { r: 118, g: 29, b: 232 };
// const color5 = { r: 133, g: 39, b: 231 };
const color1 = { r: 15, g: 0, b: 118 };
const color2 = { r: 80, g: 62, b: 200 };
const color3 = { r: 15, g: 0, b: 118 };
const color4 = { r: 80, g: 62, b: 200 };
const color5 = { r: 15, g: 0, b: 118 };
const color6 = { ...color2 };
const color7 = { ...color1 };
const colors = [color1, color2, color3, color4, color5, color6, color7];

class HomeGradient extends Scene {
  constructor() {
    super();

    const graphics = new Graphics();
    const filter = new Filter(null, frag, {
      resolution: [window.innerWidth, window.innerHeight],
      colorTl: [...rgb(0, 0, 0), 1.0],
      colorBr: [...rgb(0, 0, 0), 1.0],
    });

    graphics.beginFill(0x0f0076);
    graphics.drawRect(0, 0, window.innerWidth, window.innerHeight);
    graphics.endFill();
    graphics.filters = [filter];

    this.graphics = graphics;
    this.container.addChild(graphics);
    this.container.zIndex = 1;
    this.progress = 0;
    this.filter = filter;
    this.colorTl = { r: 0, g: 0, b: 0, a: 1 };
    this.colorBr = { r: 0, g: 0, b: 0, a: 1 };
  }

  bind() {
    super.bind();
    this.render = this.render.bind(this);
  }

  handleResize() {
    const { graphics } = this;
    graphics.clear();
    graphics.beginFill(0x0f0076);
    graphics.drawRect(0, 0, window.innerWidth, window.innerHeight);
    graphics.endFill();
  }

  render(delta) {
    this.progress += 0.0035 * delta;
    const p1 = this.progress % 6;
    const p2 = (this.progress + 0.5) % 6;

    blend(colors[Math.floor(p1)], colors[Math.ceil(p1)], p1 % 1, this.colorTl);
    blend(colors[Math.floor(p2)], colors[Math.ceil(p2)], p2 % 1, this.colorBr);

    this.filter.uniforms.colorTl[0] = this.colorTl.r / 255;
    this.filter.uniforms.colorTl[1] = this.colorTl.g / 255;
    this.filter.uniforms.colorTl[2] = this.colorTl.b / 255;

    this.filter.uniforms.colorBr[0] = this.colorBr.r / 255;
    this.filter.uniforms.colorBr[1] = this.colorBr.g / 255;
    this.filter.uniforms.colorBr[2] = this.colorBr.b / 255;
  }
}

export default HomeGradient;
