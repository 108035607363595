import { css } from 'styled-components';
import { em } from 'polished';

export const maxBreakpoints = {
  mobile: 767,
  tabletPortrait: 1023,
  tabletLandscape: 1279,
};

export const minBreakpoints = {
  xxlarge: 1800,
};

export const mqTabletLandscape = `(max-width: ${maxBreakpoints.tabletLandscape}px)`;
export const mqTabletPortrait = `(max-width: ${maxBreakpoints.tabletPortrait}px)`;
export const mqTabletPortraitOnly = `(max-width: ${maxBreakpoints.tabletPortrait}px) and (min-width: ${maxBreakpoints.mobile}px)`;
export const mqMobile = `(max-width: ${maxBreakpoints.mobile}px)`;

// Iterate through the sizes and create a media template
let media = Object.keys(maxBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${em(maxBreakpoints[label])}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

media = Object.keys(minBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${em(minBreakpoints[label])}) {
      ${css(...args)}
    }
  `;

  return acc;
}, media);

export default media;
