import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import styled from 'styled-components';
import { Title2 } from 'styles/typography';
import Grid from './Grid';
import { useGlobalContext } from 'store/GlobalProvider';
import SelectedWorksScene from 'webgl/scenes/SelectedWorks';
import { motion, useSpring } from 'framer-motion';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import SelectedWorksItem from './SelectedWorksItem';
import { gentle_slow, stiff } from 'constants/springs';
import useIntersectionObserver from 'hooks/useIntersectionObserver';

const NAV_VARIANTS = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: false,
    },
  },
  hidden: {
    opacity: 0,
  },
};

const SelectedWorksComponent = ({ setActiveWorkUid, title, works, ...others }) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const $nav = useRef();

  // ------------------------------------------------------------
  //    HOOKS
  // ------------------------------------------------------------
  const { transitionStatus, exit } = useTransitionState();
  const isExiting = transitionStatus === 'exiting' || transitionStatus === 'exited';
  const { webgl, ww, wh, scrollView, isMobile } = useGlobalContext();
  const isIntersecting = useIntersectionObserver($nav, { root: scrollView, threshold: 0.0 });
  const status = isIntersecting ? (isExiting ? 'exit' : 'visible') : 'hidden';
  const scaleToViewport = exit?.state?.shouldScaleWork || false;
  const [images, setImages] = useState([]);
  const [scene, setScene] = useState(null);

  // ------------------------------------------------------------
  //    SPRINGS
  // ------------------------------------------------------------
  const physics = { type: 'spring', damping: 18, stiffness: 120 };
  const scale = { type: 'spring', stiffness: 210, damping: 24 };
  const width = useSpring(200, scaleToViewport ? physics : scale);
  const height = useSpring(300, scaleToViewport ? physics : scale);
  const rotation = useSpring(0, scaleToViewport ? physics : gentle_slow);
  const x = useSpring(100, { ...physics, stifness: 90 });
  const y = useSpring(300, { ...physics, stifness: 90 });
  const opacity = useSpring(0, stiff);
  const originX = useSpring(0.5, gentle_slow);

  // ------------------------------------------------------------
  //    LIFECYCLE
  // ------------------------------------------------------------
  useMount(() => {
    setImages(works.map(({ uid, data }) => ({ uid, img: data.cover.url })));
    x.set(window.innerWidth / 2);
    y.set(window.innerHeight / 2);
  });

  useUnmount(() => {
    if (scene && webgl) {
      scene.disconnect(webgl.app);
    }
  });

  // ------------------------------------------------------------
  //    FUNCTIONS / HANDLERS
  // ------------------------------------------------------------
  const handleScaleToViewport = useCallback(() => {
    rotation.set(0);
    const ratio = 16 / 9;
    const w = Math.max(ww, wh * ratio);
    const h = Math.max(wh, ww / ratio);
    width.set(w);
    height.set(h);
    x.set(ww / 2);
    y.set(wh / 2);
    originX.set(0.5);
  }, [width, height, x, y, rotation, originX, ww, wh]);

  const handleSceneCreation = useCallback(
    (webgl) => {
      const scene = new SelectedWorksScene({
        width,
        height,
        rotation,
        x,
        y,
        opacity,
        originX,
        data: images,
      });

      setScene(scene);

      scene.connect(webgl.app);

      return () => {
        scene.disconnect(webgl.app);
        scene.destroy();
        setScene(null);
      };
    },
    [images, width, height, rotation, x, y, opacity, originX, setScene]
  );

  // ------------------------------------------------------------
  //    EFFECTS
  // ------------------------------------------------------------
  useEffect(() => {
    if (scaleToViewport && !isMobile) {
      handleScaleToViewport();
    }
  }, [scaleToViewport, handleScaleToViewport, isMobile]);

  useEffect(() => {
    if (webgl && !isMobile) {
      return handleSceneCreation(webgl);
    }
  }, [webgl, handleSceneCreation, isMobile]);

  // ------------------------------------------------------------
  //    RENDER
  // ------------------------------------------------------------
  return (
    <Grid {...others}>
      <Content variants={NAV_VARIANTS} initial="hidden" animate={status}>
        <Title2>{title.text}</Title2>
        <Nav ref={$nav}>
          {works.map(({ url, data, uid }) => (
            <SelectedWorksItem
              key={uid}
              uid={uid}
              to={url}
              x={x}
              y={y}
              rotation={rotation}
              width={width}
              height={height}
              opacity={opacity}
              scene={scene}
              originX={originX}
              mouseOutDisabled={scaleToViewport}
              isIntersecting={isIntersecting}
            >
              {data.title.text}
            </SelectedWorksItem>
          ))}
        </Nav>
      </Content>
    </Grid>
  );
};

const Content = styled(motion.div)`
  grid-column: 2 / -2;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;
`;

export default styled(SelectedWorksComponent)`
  box-sizing: border-box;
  min-height: 100vh;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.s12} 0;

  ${Title2} {
    opacity: 0.5;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.s2};
  }
`;
