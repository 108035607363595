import React from 'react';
import styled from 'styled-components';
import { useGlobalContext } from 'store/GlobalProvider';
import { motion } from 'framer-motion';
import { fluidPx } from 'styles/utils';
import Icon from './Icon';

const Cursor = (props) => {
  const { cursorX, cursorY, cursorState } = useGlobalContext();

  return (
    <motion.div
      {...props}
      data-state={cursorState}
      style={{ x: cursorX, y: cursorY }}
      initial={{ scale: 0 }}
      animate={{
        scale: cursorState !== null ? 1 : 0.5,
        opacity: cursorState !== null ? 1 : 0,
      }}
    >
      <Icon id="arrow-right" />
    </motion.div>
  );
};

export default styled(Cursor)`
  position: fixed;
  top: 0;
  left: 0;
  margin-top: ${fluidPx(-20)};
  margin-left: ${fluidPx(-20)};
  border-radius: ${({ theme }) => theme.spacing.s2};
  width: ${({ theme }) => theme.spacing.s4};
  height: ${({ theme }) => theme.spacing.s4};
  background: ${({ theme }) => theme.colors.black};
  z-index: 500;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};

  ${Icon} {
    transition: transform 0.2s;
  }

  &[data-state='link'] ${Icon} {
    transform: rotate(-45deg);
  }

  &[data-state='right'] ${Icon} {
    transform: rotate(0deg);
  }

  &[data-state='left'] ${Icon} {
    transform: rotate(-180deg);
  }
`;
