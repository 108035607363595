import { useMotionValue } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import About from './About';
import Pane from './Pane';

const PaneAbout = (props) => {
  const scroll = useMotionValue(0);
  const [scrollY, setScrollY] = useState(scroll);

  const handleScrollMount = useCallback(
    ({ y }) => {
      setScrollY(y);
    },
    [setScrollY]
  );

  return (
    <Pane {...props} onScrollableMount={handleScrollMount}>
      <About scrollY={scrollY} />
    </Pane>
  );
};

export default PaneAbout;
