import React, { useCallback, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ImgixOrSVG, { getSizesAttr } from 'components/ImgixOrSVG';
import Scrollable, { hiddenScrollbarCSS, Item as ScrollableItem } from 'components/Scrollable';
import { sizeAsColVw } from 'styles/utils';
import CarouselScrollbar from 'components/CarouselScrollbar';
import { useMotionValue } from 'framer-motion';
import { useGlobalContext } from 'store/GlobalProvider';
import { useScrollbarWidth } from 'react-use';
import media from 'styles/media';

const SIDE_COL_PADDING = 2;

const CarouselComponent = ({ primary, items, ...others }) => {
  const { item_columns: main_item_columns } = primary;
  const defaultCarouselScroll = useMotionValue(0);
  const [carousel, setCarousel] = useState(defaultCarouselScroll);
  const { cursorX, cursorY, setCursorState, ww, hasMouse } = useGlobalContext();
  const sbw = useScrollbarWidth();
  const w = ww - sbw;

  const filtered = items.filter(({ image, video }) => {
    const hasImage = !!image?.url;
    return hasImage;
  });

  const handleScrollMount = useCallback(
    ({ view, x }) => {
      setCarousel({ view, x });
    },
    [setCarousel]
  );

  const handleMouseEnter = (event) => {
    const isRight = event.pageX > w / 2;
    cursorX.current = cursorX.prev = event.pageX;
    cursorY.current = cursorY.prev = event.pageY;
    setCursorState(isRight ? 'right' : 'left');
  };

  const handleMouseLeave = (event) => {
    setCursorState(null);
  };

  const handleMouseMove = (event) => {
    const isRight = event.pageX > w / 2;
    cursorX.set(event.pageX);
    cursorY.set(event.pageY);
    setCursorState(isRight ? 'right' : 'left');
  };

  const handleClick = (event) => {
    const isRight = event.pageX > w / 2;
    const scrollPadding = (w / 24) * (SIDE_COL_PADDING + 1);
    const scrollAmount = (w - scrollPadding) * (isRight ? 1 : -1);
    carousel.view.scrollLeft = carousel.view.scrollLeft + scrollAmount;
  };

  const listeners = hasMouse
    ? {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onMouseMove: handleMouseMove,
        onClick: handleClick,
      }
    : {};

  return (
    <div {...others}>
      <Scrollable
        direction="horizontal"
        onMount={handleScrollMount}
        hasScrollbar={false}
        {...listeners}
      >
        {filtered.map(({ item_columns, image, video, video_mobile }) => {
          const colsWidth = item_columns || main_item_columns || 6;

          const imageProps = {
            ...image,
            isNativeDimensions: true,
            sizes: getSizesAttr({ cols: colsWidth }),
          };

          return (
            <Item key={image.url} colsWidth={colsWidth}>
              <div>
                <ImgixOrSVG {...imageProps} />
              </div>
            </Item>
          );
        })}
      </Scrollable>
      <CarouselScrollbar carousel={carousel} />
    </div>
  );
};

const Item = styled.div`
  width: ${({ colsWidth }) => sizeAsColVw(colsWidth)};
  padding-top: 20px;
  padding-bottom: 20px;

  & > div {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: var(--project-background-color);
  }

  ${media.mobile`
    width: ${({ colsWidth }) => sizeAsColVw(colsWidth < 10 ? 17 : 22)};
  `}
`;

export default styled(CarouselComponent)`
  background: var(--page-background-color, inherit);
  padding-bottom: ${({ theme }) => theme.spacing.s15};

  ${CarouselScrollbar} {
    margin-top: ${({ theme }) => `calc(${theme.spacing.s5} - 20px)`};
  }

  ${Scrollable} {
    ${hiddenScrollbarCSS};
    cursor: pointer;
  }

  ${ScrollableItem} {
    padding-left: ${sizeAsColVw(1)};

    &:first-child {
      padding-left: ${sizeAsColVw(SIDE_COL_PADDING)};
    }

    &:last-child {
      padding-right: ${sizeAsColVw(SIDE_COL_PADDING)};
    }
  }

  ${media.mobile`
    padding-bottom: ${({ theme }) => theme.spacing.s4};

    ${CarouselScrollbar} {
      margin-top: ${({ theme }) => `calc(${theme.spacing.s3} - 20px)`};
    }

    ${ScrollableItem} {
      padding-left: ${sizeAsColVw(2)};

      &:first-child {
        padding-left: ${sizeAsColVw(1)};
      }

      &:last-child {
        padding-right: ${sizeAsColVw(1)};
      }
    }
  `}
`;

export const query = graphql`
  fragment ProjectCarousel on PrismicProjectDataBodyCarousel {
    id
    slice_type
    slice_label
    primary {
      item_columns
    }
    items {
      item_columns
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
    }
  }
`;
