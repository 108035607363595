// import { rem, rgba } from 'polished';
import { rgba } from 'polished';
import easings from './easings';
import { fluidPx } from './utils';

const headerHeight = 40;
const headerHeightMobile = 90;
const defaultGridSize = 24;
const designedDesktop = 1440;
const designedMobile = 375;

const colors = {
  black: '#000000',
  white: '#ffffff',
  blackPearl: '#091433',
  blueDark: '#503EC8',
  turquoise: '#C6DBCD',
};

const theme = {
  headerHeight,
  headerHeightMobile,
  defaultGridSize,
  designedDesktop,
  designedMobile,
  zIndex: {
    menu: 11,
    header: 10,
    pane: 9,
  },
  colors: {
    metaThemeColor: colors.black,
    white: colors.white,
    background: colors.white,
    blackPearl: colors.blackPearl,
    black: colors.black,
    overlayHeroProject: rgba(colors.black, 0.2),
    black10p: rgba(colors.black, 0.1),
    blueDark: colors.blueDark,
    turquoise: colors.turquoise,
  },
  fontWeight: {
    light: 300,
    normal: 400,
    semibold: 500,
  },
  fontSize: {
    base: 16,
  },
  lineHeight: {
    base: 2,
  },
  spacing: {
    s2: fluidPx(20),
    s3: fluidPx(30),
    s4: fluidPx(40),
    s5: fluidPx(50),
    s7: fluidPx(70),
    s9: fluidPx(90),
    s10: fluidPx(100),
    s12: fluidPx(120),
    s15: fluidPx(150),
    s18: fluidPx(180),
    s28: fluidPx(280),
  },
  easings,
};

export default theme;
