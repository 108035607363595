import { css } from 'styled-components';

export const linkReset = css`
  text-decoration: none;
  outline: none;
  color: currentColor;
  cursor: pointer;
`;

export const buttonReset = css`
  text-decoration: none;
  font: inherit;
  border-radius: 0;
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
  text-transform: inherit;
`;
