import theme from './theme';

export function fluidPx(px, variable = '--fluidPx') {
  return `calc(var(${variable}) * ${parseFloat(px, 10)})`;
}

export function sizeAsColVw(numberOfCols, gridSize = theme.defaultGridSize) {
  return `calc(${(100 / gridSize) * numberOfCols} * var(--vw))`;
}

export function sizeAsColPercent(numberOfCols, gridSize = theme.defaultGridSize) {
  return `${(100 / gridSize) * numberOfCols}%`;
}
