import { gentle } from 'constants/springs';
import { useMotionValue, useSpring } from 'framer-motion';
import React, { createContext, useState, useContext } from 'react';
import { useMedia, useWindowSize } from 'react-use';
import { mqMobile, mqTabletPortrait } from 'styles/media';

export const GlobalContext = createContext({
  savedScrollTop: 0,
  scrollEnabled: true,
  cursorX: { x: 0, y: 0 },
  cursorY: { x: 0, y: 0 },
  cursorState: false,
});

const GlobalProvider = ({ children }) => {
  const scroll = useMotionValue(0);
  const isMobile = useMedia(mqMobile);
  const isTabletPortrait = useMedia(mqTabletPortrait);
  const hasMouse = useMedia('(hover: hover) and (pointer: fine)');
  const [isPhoneOrTablet, setIsPhoneOrTablet] = useState(false);
  const { width: ww, height: wh } = useWindowSize();
  const [scrollView, setScrollView] = useState(null);
  const [scrollY, setScrollY] = useState(scroll);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [savedScrollTop, setSavedScrollTop] = useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const cursorX = useSpring(0, gentle);
  const cursorY = useSpring(0, gentle);
  const [cursorState, setCursorState] = useState(null);
  const [webgl, setWebgl] = useState(null);
  const [paneId, setPaneId] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        hasMouse,
        isTabletPortrait,
        isPhoneOrTablet,
        setIsPhoneOrTablet,
        ww,
        wh,
        isMenuOpen,
        setMenuOpen,
        isMobile,
        scrollView,
        setScrollView,
        scrollY,
        setScrollY,
        scrollEnabled,
        setScrollEnabled,
        savedScrollTop,
        setSavedScrollTop,
        cursorX,
        cursorY,
        cursorState,
        setCursorState,
        webgl,
        setWebgl,
        paneId,
        setPaneId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw Error('useGlobalContext must be used inside a `GlobalProvider`');
  }
  return context;
};
