import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

const Page404 = withPrismicUnpublishedPreview(({ data }) => {
  if (!data?.prismicPage404?.data) return null;

  const { title, ...others } = data.prismicPage404.data;

  const hasTitle = !!title?.text;

  return (
    <Module>
      <SEO {...others} />
      {hasTitle && <h1>{title.text}</h1>}
    </Module>
  );
});

const Module = styled.div``;

export default Page404;

export const pageQuery = graphql`
  query Page404Query($uid: String!, $lang: String!) {
    prismicPage404(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        seo_og_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
      }
    }
  }
`;
