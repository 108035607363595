import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import HeaderProvider from 'store/HeaderProvider';
import PageProvider from 'store/PageProvider';
import { useGlobalContext } from 'store/GlobalProvider';
import GlobalStyle from './GlobalStyle';
import Header from './Header';
import Helmet from 'react-helmet';
import { AnimatePresence } from 'framer-motion';
import Cursor from './Cursor';
import { useMount, useScrollbarWidth } from 'react-use';
import PaneAbout from './PaneAbout';
import Menu from './Menu';

const Layout = ({ children, pageContext, location, ...others }) => {
  const sbw = useScrollbarWidth();
  const { savedScrollTop, scrollEnabled, paneId, ww, setIsPhoneOrTablet } = useGlobalContext();

  const handleTouchScroll = useCallback((event) => {
    // Disable scroll on touch device with allowance for .scrollable elements
    if (
      !event.target.classList.contains('scrollable') &&
      event.target.closest('.scrollable') === null
    ) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (scrollEnabled) {
      window.removeEventListener('touchmove', handleTouchScroll);
      document.body.style.removeProperty('top');
      document.scrollingElement.scrollTo(0, savedScrollTop);
    } else {
      document.body.style.top = `${-savedScrollTop}px`;
      window.addEventListener('touchmove', handleTouchScroll, { passive: false });
    }
  }, [handleTouchScroll, scrollEnabled, savedScrollTop]);

  useMount(() => {
    setIsPhoneOrTablet(/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent));
  });

  return (
    <PageProvider pageContext={pageContext}>
      <Helmet>
        {/* eslint-disable */}
        <link rel="apple-touch-icon" sizes="180x180" href="/images/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/images/favicon/site.webmanifest" />
        <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/images/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="msapplication-config" content="/images/favicon/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        {/* eslint-enable */}
      </Helmet>
      <GlobalStyle scrollEnabled={scrollEnabled} vwUnit={(ww - sbw) / 100} />
      <Main>
        <HeaderProvider>
          <Header location={location} />
          <Menu location={location} />
        </HeaderProvider>
        {children}
        <AnimatePresence>
          {paneId && paneId == 'page_about' && <PaneAbout key={paneId} uid={paneId} />}
        </AnimatePresence>
      </Main>
      <Cursor />
    </PageProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Main = styled.main`
  height: 100%;
`;

export default Layout;
