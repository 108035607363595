import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Grid from 'components/Grid';
import RichText from 'components/RichText';
import { font20, font28, font36, font70 } from 'styles/typography';
import media from 'styles/media';

const TextComponent = ({ primary, ...others }) => {
  const { is_full_height, content } = primary;

  return (
    <Module {...others} isFullHeight={is_full_height}>
      <RichText field={content.richText} />
    </Module>
  );
};

const Module = styled(Grid)`
  box-sizing: border-box;
  padding-top: ${({ isFullHeight, theme }) => (isFullHeight ? theme.spacing.s15 : null)};
  padding-bottom: ${({ isFullHeight, theme }) => theme.spacing.s15};
  min-height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : null)};
  align-items: center;

  ${media.mobile`
    padding-top: ${({ isFullHeight, theme }) => (isFullHeight ? theme.spacing.s4 : null)};
    padding-bottom: ${({ theme }) => theme.spacing.s4};
    min-height: 0;
  `}
`;

export default styled(TextComponent)`
  background: var(--page-background-color, inherit);

  ${RichText} {
    grid-column: 5 / span 16;

    ${font28};

    h2 {
      ${font70};
    }

    a {
      position: relative;
      font-style: italic;
      font-weight: bold;

      &:after {
        content: '';
        border-bottom: 2px solid currentColor;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
      }
    }
  }

  ${media.mobile`
    ${RichText} {
      grid-column: 2 / -2;

      ${font20};

      h2 {
        ${font36};
      }
    }
  `}
`;

export const query = graphql`
  fragment ProjectText on PrismicProjectDataBodyText {
    id
    slice_type
    slice_label
    primary {
      is_full_height
      content {
        richText
      }
    }
  }
`;
