import React from 'react';
import styled from 'styled-components';
import { useAboutPrismicData } from 'hooks/useAboutPrismicData';
import { Title2, font28, font20 } from 'styles/typography';
import RichText from './RichText';
import Grid from './Grid';
import ImgixOrSVG from './ImgixOrSVG';
import { sizeAsColVw } from 'styles/utils';
import { motion, useTransform } from 'framer-motion';
import { useMeasure } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import radians from 'core/maths/radians';
import { gentle, slow } from 'constants/springs';
import media from 'styles/media';

const VARIANTS_CONTENT = {
  visible: {
    x: '0%',
    opacity: 1,
    transition: { ...slow },
  },
  hidden: {
    x: '15%',
    opacity: 0,
  },
  exit: {
    opacity: 0,
    transition: { ...gentle },
  },
};

const About = ({ scrollY, ...others }) => {
  const [measure, { height }] = useMeasure();
  const { wh, isMobile } = useGlobalContext();
  const { title, content, image } = useAboutPrismicData();
  const hasTitle = !!title?.text;
  const hasContent = !!content?.richText?.length > 0;
  const hasImage = !!image?.url;

  const min_angle = -1;
  const delta = 2;
  const y = useTransform(scrollY, (y) => y * (isMobile ? 0.8 : 0.5));
  const rotate = useTransform(scrollY, (y) => min_angle + (y / (height - wh)) * delta);
  const rev_rotate = useTransform(rotate, (rotate) => -rotate);
  const scale = 1 + Math.sin(radians(delta));

  return (
    <Grid {...others} ref={measure}>
      <Content>
        {hasImage && (
          <motion.div variants={VARIANTS_CONTENT}>
            <ImageContainer style={{ y, rotate }}>
              <ImgixOrSVG {...image} style={{ rotate: rev_rotate, scale }} sizes="25vw" />
            </ImageContainer>
          </motion.div>
        )}
        <motion.div variants={VARIANTS_CONTENT}>
          {hasTitle && <Title2>{title.text}</Title2>}
          {hasContent && <RichText field={content.richText} />}
        </motion.div>
      </Content>
    </Grid>
  );
};

const ImageContainer = styled(motion.div)`
  position: absolute;
  left: ${sizeAsColVw(-4)};
  width: ${sizeAsColVw(5)};
  top: ${({ theme }) => theme.spacing.s28};
  transform: rotate(2deg);
  overflow: hidden;

  ${ImgixOrSVG} {
    width: 100%;
  }

  ${media.mobile`
    left: ${sizeAsColVw(-1)};
    width: ${sizeAsColVw(13)};
  `}
`;

const Content = styled.div`
  position: relative;
  grid-column: -12 / -4;
  padding: ${({ theme }) => `${theme.spacing.s15} 0 ${theme.spacing.s15}`};

  ${Title2} {
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.s4};
  }

  ${RichText} {
    position: relative;
    ${font28}
  }

  ${media.mobile`
    padding: ${({ theme }) => `${theme.spacing.s12} 0 ${theme.spacing.s5}`};
    grid-column: 2 / -2;

    ${Title2} {
      margin-bottom: ${({ theme }) => theme.spacing.s2};
    }

    ${RichText} {
      position: relative;
      ${font20}
    }
  `}
`;

export default styled(About)`
  grid-template-columns: repeat(20, minmax(10px, 1fr));
`;
