import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from 'store/PageProvider';

export const useAboutPrismicData = () => {
  const { lang } = usePageContext();

  const { allPrismicPageAbout } = useStaticQuery(
    graphql`
      query PageAboutData {
        allPrismicPageAbout {
          edges {
            node {
              uid
              lang
              data {
                title {
                  text
                }
                content {
                  richText
                }
                image {
                  alt
                  url
                  dimensions {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const localized = allPrismicPageAbout.edges.find(({ node }) => node.lang === lang);

  return localized?.node?.data;
};
