import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Grid from 'components/Grid';
import ImgixOrSVG, { getSizesAttr } from 'components/ImgixOrSVG';
import { useGlobalContext } from 'store/GlobalProvider';
import Video from 'components/Video';
import AppearingFlip from 'components/AppearingFlip';
import media from 'styles/media';

const SUBGRID_COLS_SIZE = 20;

const MonoComponent = ({ primary, ...others }) => {
  const { isMobile } = useGlobalContext();
  const [isContentLoaded, setContentLoaded] = useState(false);

  const {
    background_color_override,
    accent_color_override,
    columns_padding,
    columns,
    image,
    video,
    video_mobile,
  } = primary;

  const hasPadding = columns_padding !== null && columns_padding !== 0;
  const hasColumns = columns !== null && columns !== 0;
  const hasImage = !!image?.url;
  const hasVideo = !!video?.url;
  const isCentered = !hasPadding && columns > 0;
  const hasVideoMobile = !!video_mobile?.url;

  const columnStart = hasPadding ? columns_padding + 1 : 1;
  const columnSpan = hasColumns
    ? columns
    : hasPadding
    ? SUBGRID_COLS_SIZE - columns_padding * 2
    : SUBGRID_COLS_SIZE;

  const styledProps = {
    isCentered,
    columnStart,
    columnSpan,
  };

  const imageProps = hasImage && {
    ...image,
    isNativeDimensions: true,
    sizes: getSizesAttr({ cols: columnSpan }),
  };

  const videoProps = hasVideo && {
    poster: hasImage ? image.url : null,
    src: isMobile && hasVideoMobile ? video_mobile.url : video.url,
  };

  return (
    <Grid {...others}>
      <SubGrid
        {...styledProps}
        hasPadding={hasPadding}
        style={{
          '--project-background-color': background_color_override,
          '--project-accent-color': accent_color_override,
        }}
      >
        <Item {...styledProps}>
          <AppearingFlip ready={isContentLoaded}>
            {hasImage && <ImgixOrSVG {...imageProps} onLoad={() => setContentLoaded(true)} />}
            {hasVideo && <Video {...videoProps} onLoad={() => setContentLoaded(true)} />}
          </AppearingFlip>
        </Item>
      </SubGrid>
    </Grid>
  );
};

const SubGrid = styled(Grid)`
  background: var(--project-background-color);
  padding-top: ${({ theme, hasPadding }) => (hasPadding ? theme.spacing.s12 : null)};
  padding-bottom: ${({ theme, hasPadding }) => (hasPadding ? theme.spacing.s12 : null)};
  grid-column-start: ${({ isCentered, columnSpan }) =>
    isCentered ? (24 - columnSpan) / 2 + 1 : '3'};
  grid-column-end: ${({ isCentered, columnSpan }) =>
    isCentered ? `span ${columnSpan}` : 'span 20'};
  grid-template-columns: ${({ isCentered, columnSpan }) =>
    `repeat(${isCentered ? columnSpan : SUBGRID_COLS_SIZE}, 1fr)`};

  ${media.mobile`
    padding-top: ${({ theme, hasPadding }) => (hasPadding ? theme.spacing.s5 : null)};
    padding-bottom: ${({ theme, hasPadding }) => (hasPadding ? theme.spacing.s5 : null)};

    grid-column-start: 1;
    grid-column-end: -1;
    grid-template-columns: repeat(24, 1fr);
  `}
`;

const Item = styled.div`
  grid-column-start: ${({ isCentered, columnStart }) => (isCentered ? 1 : columnStart)};
  grid-column-end: ${({ isCentered, columnSpan }) => (isCentered ? -1 : `span ${columnSpan}`)};

  ${media.mobile`
    grid-column-start: ${({ isCentered, columnStart }) => (isCentered ? 1 : 2)};
    grid-column-end: ${({ isCentered, columnSpan }) => (isCentered ? -1 : -2)};
  `}
`;

export default styled(MonoComponent)`
  background: var(--page-background-color, inherit);
  padding-bottom: ${({ theme }) => theme.spacing.s15};

  ${media.mobile`
    padding-bottom: ${({ theme }) => theme.spacing.s4};
  `}
`;

export const query = graphql`
  fragment ProjectMono on PrismicProjectDataBodyMono {
    id
    slice_type
    slice_label
    primary {
      background_color_override
      accent_color_override
      columns_padding
      columns
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      columns
      video {
        url
      }
      video_mobile {
        url
      }
    }
  }
`;
