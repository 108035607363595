import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import About from 'components/About';
import Page from 'components/Page';
import { useGlobalContext } from 'store/GlobalProvider';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const PageAbout = withPrismicPreview(({ data, pageContext }) => {
  const { scrollY } = useGlobalContext();

  if (!data?.prismicPageAbout?.data) return null;

  const { title, body, ...others } = data.prismicPageAbout.data;

  return (
    <Module>
      <SEO {...others} />
      <Page>
        <About scrollY={scrollY} />
      </Page>
    </Module>
  );
});

const Module = styled.div`
  ${About} {
    color: ${({ theme }) => theme.colors.blueDark};
    background: ${({ theme }) => theme.colors.turquoise};
  }
`;

export default PageAbout;

export const pageQuery = graphql`
  query PageAboutQuery($uid: String!, $lang: String!) {
    prismicPageAbout(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        seo_og_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        schemaorg {
          text
        }
        title {
          text
        }
      }
    }
  }
`;
