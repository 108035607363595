import React, { Children, memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMeasure, useMount } from 'react-use';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { useGlobalContext } from 'store/GlobalProvider';

const ROOT_MARGIN = 100;

const ScrollableItem = memo(({ children, x: sx, y: sy, ww, wh, index }) => {
  const $item = useRef();
  const [{ top, left, height, width }, setBounds] = useState({});
  const bTop = top + height + ROOT_MARGIN;
  const bBot = wh - top + ROOT_MARGIN;
  const x = useTransform(sx, [left + width, (ww - left) * -1], [(left + width) * -1, ww - left]);
  const y = useTransform(sy, [bTop, bBot * -1], [bTop * -1, bBot]);
  const visibility = useTransform(y, (y) => (y <= bTop * -1 || y >= bBot ? 'hidden' : 'visible'));
  const willChange = useTransform(visibility, (v) => (v === 'visible' ? 'transform' : null));

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      setBounds({
        top: entry.target.offsetTop,
        left: entry.target.offsetLeft,
        height: entry.target.offsetHeight,
        width: entry.target.offsetWidth,
      });
    });

    if ($item.current) {
      observer.observe($item.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Item
      ref={$item}
      style={{ x, y, visibility, willChange }}
      transformTemplate={({ x, y }) => `translate(${x}, ${y})`}
    >
      {children}
    </Item>
  );
});

ScrollableItem.displayName = 'ScrollableItem';

const Scrollable = ({
  children,
  direction = 'vertical',
  hasScrollbar = true,
  onChangeScrollProp = () => {},
  onMount = () => {},
  ...others
}) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const $scrollView = useRef();

  // ------------------------------------------------------------
  //    HOOKS
  // ------------------------------------------------------------
  const { wh, ww } = useGlobalContext();
  const [moduleMeasure, { width: mw, height: mh }] = useMeasure();
  const [contentMeasure, { width, height }] = useMeasure();

  // ------------------------------------------------------------
  //    FRAMER
  // ------------------------------------------------------------
  const physics = { damping: 15, mass: 0.27, stiffness: 55 };

  const { scrollX, scrollY } = useScroll({ container: $scrollView });
  const x = useSpring(scrollX, physics);
  const y = useSpring(scrollY, physics);

  useMount(() => {
    onMount({
      view: $scrollView.current,
      x,
      y,
    });
  });

  useEffect(() => {
    moduleMeasure($scrollView.current);

    return () => {
      moduleMeasure(null);
    };
  }, [moduleMeasure]);

  return (
    <div
      ref={$scrollView}
      {...others}
      style={{ display: 'flex', flexDirection: direction === 'vertical' ? 'column' : 'row' }}
    >
      <Sticky style={{ width: '100%', height: '100%', maxWidth: mw }}>
        <Content
          ref={contentMeasure}
          style={{
            flexDirection: direction === 'vertical' ? 'column' : 'row',
            width: direction === 'vertical' ? null : 'max-content',
          }}
        >
          {Children.map(children, (child, index) => (
            <ScrollableItem key={`item-${index}`} x={x} y={y} ww={ww} wh={wh}>
              {child}
            </ScrollableItem>
          ))}
        </Content>
      </Sticky>
      <Sizer style={{ width: Math.max(1, width - mw), height: Math.max(1, height - mh) }} />
    </div>
  );
};

export const Sticky = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & > * {
    flex-shrink: 0;
  }
`;

export const Sizer = styled.div`
  flex-shrink: 0;
`;

export const Item = styled(motion.div)``;

export const hiddenScrollbarCSS = css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default styled(memo(Scrollable))`
  position: relative;
  overflow: auto;
  overscroll-behavior-x: none;
  -webkit-overflow-scrolling: none;
`;
