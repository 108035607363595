import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { PrismicRichText, Element } from '@prismicio/react';
import linkResolver from 'core/linkResolver';
import Markdown from 'markdown-to-jsx';
import Link from './LinkCursor';
import ImgixOrSVG from './ImgixOrSVG';
import LinkImage from './LinkImage';

const MarkdownLinkProxy = ({ href, ...others }) => <Link to={href} {...others} />;

const reactSerializer = function (type, element, content, children, index) {
  const key = `element-${type}-${index + 1}`;

  switch (type) {
    // Don't wrap images in a <p> tag
    case Element.image:
      return (
        <figure key={key}>
          <ImgixOrSVG {...element} />
          <img src={element.url} alt={element.alt} />
          {!!element.alt && <figcaption>{element.alt}</figcaption>}
        </figure>
      );

    // Dynamic link with gatsby router
    case Element.hyperlink:
      const url = linkResolver(element.data);
      const { link_type, kind } = element.data;
      const isLinkImage = link_type === 'Media' && kind === 'image';

      return isLinkImage ? (
        <LinkImage key={key} {...element.data}>
          {content}
        </LinkImage>
      ) : (
        <Link key={key} to={url} target={element.data.target}>
          {content}
        </Link>
      );

    case Element.preformatted:
      return (
        <Markdown
          key={key}
          options={{
            overrides: {
              a: {
                component: MarkdownLinkProxy,
              },
            },
          }}
        >
          {element.text}
        </Markdown>
      );

    // Return null to stick with the default behavior for all other elements
    default:
      return null;
  }
};

const DynamicRichText = forwardRef(({ dangerouslySetInnerHTML = false, field, ...others }, ref) => {
  if (dangerouslySetInnerHTML) {
    return <div ref={ref} dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...others} />;
  } else {
    return (
      <div ref={ref} {...others}>
        <PrismicRichText field={field} components={reactSerializer} />
      </div>
    );
  }
});

DynamicRichText.displayName = 'DynamicRichText';

const RichText = styled(DynamicRichText)`
  pointer-events: none;

  &:hover .word {
    opacity: 0.5;
    animation-play-state: paused;
  }

  .word {
    transition: opacity 0.5s;
  }

  a {
    pointer-events: auto;

    &:hover .word {
      opacity: 1;
      animation-play-state: paused;
    }
  }

  & > *:first-child,
  & > div > *:first-child {
    margin-top: 0;
  }

  & > *:last-child,
  & > div > *:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  p {
    margin: 1em 0;
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  i,
  em {
    font-style: italic;
  }

  b,
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }

  u {
    text-decoration: underline;
  }
`;

export default RichText;
