import React, { useCallback, useEffect, useRef } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';

const defaults = {
  muted: true,
  playsInline: true,
  loop: true,
  preload: 'metadata',
};

const VideoComponent = ({ onLoad, ...props }) => {
  const { scrollView } = useGlobalContext();
  const $video = useRef(null);
  const vars = useRef({
    promise: null,
  });

  const handleIntersection = useCallback(([entry]) => {
    const isIntersecting = entry.isIntersecting || entry.intersectionRatio > 0;

    if (isIntersecting) {
      vars.current.promise = $video.current.play();
    } else {
      if (vars.current.promise) {
        vars.current.promise.then(() => {
          $video.current.pause();
          vars.current.promise = null;
        });
      } else {
        $video.current.pause();
      }
    }
  }, []);

  const handleLoadedData = useCallback(() => {
    if ($video.current?.readyState >= 3) {
      onLoad();
    }
  }, [$video, onLoad]);

  useEffect(() => {
    const $node = $video.current;

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px',
      root: scrollView,
      threshold: 0,
    });

    if ($node) {
      observer.observe($node);
    }

    return () => {
      if ($node) {
        $node.pause();
      }

      observer.disconnect();
    };
  }, [handleIntersection, scrollView]);

  const videoProps = {
    ...defaults,
    ...props,
  };

  return <video ref={$video} {...videoProps} onLoadedData={handleLoadedData} />;
};

export default styled(VideoComponent)`
  display: block;
  width: 100%;
`;
