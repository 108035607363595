import propsToDom from 'core/helpers/propsToDom';
import { motion, useMotionValue } from 'framer-motion';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import clamp from 'core/helpers/clamp';
import { useMeasure } from 'react-use';
import { sizeAsColVw } from 'styles/utils';
import media from 'styles/media';

const CarouselScrollbar = ({ carousel, ...others }) => {
  const [measureModule, { width }] = useMeasure();
  const scroll = useRef(0);
  const x = useMotionValue(scroll);

  const getMax = useCallback(() => width - width / 5, [width]);

  useEffect(() => {
    const { view, x: cx } = carousel;

    if (view && cx) {
      return cx.onChange(() => {
        const progress = clamp(cx.get() / (view.scrollWidth - view.offsetWidth), 0, 1);
        x.set(progress * getMax());
      });
    }
  }, [x, carousel, getMax]);

  const onPan = useCallback(
    (event, info) => {
      const px = clamp(scroll.current + info.delta.x, 0, getMax());
      const progress = clamp(px / getMax(), 0, 1);
      carousel.view.scrollTo(carousel.view.scrollWidth * progress, 0);
      scroll.current = px;
    },
    [carousel, getMax]
  );

  const onPanStart = useCallback((event, info) => {}, []);
  const onPanEnd = useCallback((event, info) => {}, []);

  return (
    <div ref={measureModule} {...propsToDom(others)}>
      <Draggable onPan={onPan} onPanStart={onPanStart} onPanEnd={onPanEnd} style={{ x }} />
    </div>
  );
};

const Draggable = styled(motion.div)`
  position: absolute;
  left: 0;
  width: 20%;
  touch-action: pan-y;
  user-select: none;
  top: -100%;
  height: 300%;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: ${(1 / 3) * 100}%;
    right: 0;
    left: 0;
    height: ${(1 / 3) * 100}%;
    background: var(--hero-background-color, ${({ theme }) => theme.colors.black});
    transition: transform 0.3s;
  }

  &:hover:before {
    transform: scaleY(1.5);
  }

  &:active:before {
    transform: scaleY(1.5);
  }
`;

export default styled(CarouselScrollbar)`
  position: relative;
  margin: 0 auto;
  width: ${sizeAsColVw(4)};
  background: ${({ theme }) => theme.colors.black10p};
  height: 3px;

  ${media.mobile`
    width: ${sizeAsColVw(15)};
  `}
`;
