import React from 'react';
import Link from './Link';
import styled from 'styled-components';
import { useGlobalContext } from 'store/GlobalProvider';

const LinkCursor = (props) => {
  const { cursorX, cursorY, setCursorState, hasMouse } = useGlobalContext();

  const handleMouseMove = (event) => {
    cursorX.set(event.pageX);
    cursorY.set(event.pageY);
  };

  const handleMouseEnter = (event) => {
    cursorX.current = cursorX.prev = event.pageX;
    cursorY.current = cursorY.prev = event.pageY;
    setCursorState('link');
  };

  const handleMouseLeave = (event) => {
    setCursorState(null);
  };

  const listeners = hasMouse
    ? {
        onMouseMove: handleMouseMove,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }
    : {};

  return <Link {...props} {...listeners} />;
};

export default styled(LinkCursor)``;
