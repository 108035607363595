import beziers from 'core/beziers';
import propsToDom from 'core/helpers/propsToDom';
import { motion, useAnimation } from 'framer-motion';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { useCallback, useEffect, useRef } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import sleep from 'core/sleep';

const transition = {
  type: 'tween',
  duration: 0.85,
  ease: beziers.easeOutExpo,
};

const initialScaleX = 1;

const AppearingFlip = ({ children, delay, once = true, ready = true, ...others }) => {
  // ------------------------------------------------------------
  //    REFS
  // ------------------------------------------------------------
  const $element = useRef(null);

  // ------------------------------------------------------------
  //    HOOKS
  // ------------------------------------------------------------
  const { scrollView } = useGlobalContext();
  const isIntersecting = useIntersectionObserver($element, {
    rootMargin: '-40% 0px -20% 0px',
    root: scrollView,
    threshold: 0.0,
    once: true,
  });

  // ------------------------------------------------------------
  //    ANIMATIONS
  // ------------------------------------------------------------
  const overlay = useAnimation();
  const content = useAnimation();

  const sequence = useCallback(async () => {
    // await overlay.start({ scaleX: 1, transition });
    await sleep(delay);
    content.start({ visibility: null });
    await overlay.start({ originX: 1 });
    return await overlay.start({ scaleX: 0, transition });
  }, [overlay, content, delay]);

  // ------------------------------------------------------------
  //    EFFECTS
  // ------------------------------------------------------------
  useEffect(() => {
    if (isIntersecting && ready) {
      sequence();
    }
  }, [isIntersecting, ready, sequence]);

  // ------------------------------------------------------------
  //    RENDERING
  // ------------------------------------------------------------
  return (
    <div ref={$element} {...propsToDom(others)}>
      <motion.div initial={{ visibility: 'hidden' }} animate={content}>
        {children}
      </motion.div>
      <Overlay initial={{ originX: 0, scaleX: initialScaleX }} animate={overlay} />
    </div>
  );
};

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background: var(--project-accent-color);
  transform: 0% 50%;
`;

export default styled(AppearingFlip)`
  position: relative;
  overflow: hidden;
  transform-origin: 0% 50%;
`;
