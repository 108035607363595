import { Container } from 'pixi.js';

class AbstractScene {
  constructor() {
    const container = new Container();
    this.shouldResize = this.handleResize instanceof Function;
    this.bind();
    this.events(true);
    this.container = container;
  }

  bind() {
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.destroy = this.destroy.bind(this);

    if (this.shouldResize) {
      this.handleResize = this.handleResize.bind(this);
    }
  }

  events(listen = false) {
    const method = listen ? 'addEventListener' : 'removeEventListener';

    if (this.shouldResize) {
      window[method]('resize', this.handleResize);
    }
  }

  connect(app) {
    if (app.stage && this.container) {
      app.stage.addChild(this.container);
      if (this.render instanceof Function) {
        app.ticker.add(this.render);
      }
    }
  }

  disconnect(app) {
    if (app.stage && this.container) {
      if (this.render instanceof Function) {
        app.ticker.remove(this.render);
      }
      app.stage.removeChild(this.container);
    }
  }

  destroy() {
    this.events(false);
    this.container.destroy({ children: true });
  }
}

export default AbstractScene;
