import 'styles/global.css';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import GlobalProvider from 'store/GlobalProvider';
import Layout from 'components/Layout';
import linkResolver from 'core/linkResolver';

// Preview
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews';

// Templates
import Page from 'templates/page';
import PageAbout from 'templates/about';
import PageHome from 'templates/home';
import Page404 from 'templates/404';
import Project from 'templates/project';

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            page: Page,
            page_home: PageHome,
            page_about: PageAbout,
            page_404: Page404,
            project: Project,
          }),
        },
      ]}
    >
      <ThemeProvider theme={theme}>
        <GlobalProvider>{element}</GlobalProvider>
      </ThemeProvider>
    </PrismicPreviewProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  if (props.pageContext.fromPrismic) {
    return <Layout {...props}>{element}</Layout>;
  } else {
    return element;
  }
};
