import clamp from 'core/helpers/clamp';
import useBounds from 'hooks/useBounds';
import React, { useEffect, useRef } from 'react';
import { usePrevious, useUnmount } from 'react-use';
import styled from 'styled-components';
import { font45, font90 } from 'styles/typography';
import Link from './Link';
import radians from 'core/maths/radians';
import { motion } from 'framer-motion';
import media from 'styles/media';

const OFFSET = 0.3;

const TRANSITION = { type: 'tween', duration: 1.5, ease: [0.19, 1.0, 0.22, 1.0] };

const VARIANTS = {
  exit: { opacity: 0, y: '-100%' },
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: '100%' },
};

const SelectedWorksItemComponent = ({
  uid,
  x,
  y,
  rotation,
  width,
  height,
  opacity,
  originX,
  scene,
  mouseOutDisabled,
  children,
  isIntersecting,
  ...others
}) => {
  const vars = useRef({ isFirstMove: false, preventMouse: false });
  const [ref, bounds, refreshBounds] = useBounds();
  const prevIntersecting = usePrevious(isIntersecting);

  useUnmount(() => {
    window.removeEventListener('mousemove', handleMouseMove);
  });

  useEffect(() => {
    if (isIntersecting && !prevIntersecting) {
      refreshBounds?.();
    }
  }, [isIntersecting]);

  const handleMouseMove = (event) => {
    if (vars.current.preventMouse === true) return;
    const force = vars.current.isFirstMove === true;
    const { pageX, pageY } = event;
    const offsetX = clamp(pageX - bounds.x, 0, bounds.width);
    const mw = width.get();
    const middle = bounds.x + bounds.width / 2;
    const isOnRight = x.get() > middle;
    const isCursorRight = pageX > middle;
    const px = isCursorRight
      ? bounds.x + bounds.width + mw / 2 - mw * OFFSET
      : bounds.x + mw / -2 + mw * OFFSET;
    const wiggle = isCursorRight
      ? Math.sqrt(Math.abs(offsetX - bounds.width)) * -1
      : Math.sqrt(offsetX);

    x.set(px + wiggle * 5);
    y.set(pageY);

    if (force || (isOnRight && pageX < middle) || (!isOnRight && pageX > middle)) {
      const r =
        radians(isCursorRight ? 1 : -1) + Math.random() * radians(4) * (isCursorRight ? 1 : -1);

      const base_width = 260;
      const ratio = 300 / 460;
      const offset = (60 / 1440) * window.innerWidth;
      const random = Math.random() * offset - offset / 2;

      const w = (base_width / 1440) * window.innerWidth + random;
      const h = (base_width / ratio / 1440) * window.innerWidth + random / ratio;

      originX.set(isCursorRight ? 1 : 0);
      width.set(w);
      rotation.set(r);
      height.set(h);
    }

    vars.current.isFirstMove = false;
  };

  const handleMouseEnter = (event) => {
    if (vars.current.preventMouse) return;
    vars.current.isFirstMove = true;

    opacity.set(1);

    if (scene) {
      scene.setActiveUid(uid);
    }

    window.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseLeave = (event) => {
    window.removeEventListener('mousemove', handleMouseMove);
    if (vars.current.preventMouse) return;
    width.set(width.get() * 0.9);
    height.set(height.get() * 0.9);
    opacity.set(0);
  };

  const handleMouseDown = () => {
    vars.current.preventMouse = true;
  };

  return (
    <Link
      {...others}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onMouseLeave={handleMouseLeave}
      exit={{
        length: 1.1,
        state: { shouldScaleWork: true },
      }}
      entry={{
        appearAfter: 1,
        state: { fromScaleWork: true },
      }}
    >
      <Child variants={VARIANTS} transition={TRANSITION}>
        {children}
      </Child>
    </Link>
  );
};

const Child = styled(motion.span)`
  display: block;
`;

export default styled(SelectedWorksItemComponent)`
  position: relative;
  display: block;
  overflow: hidden;
  ${font90};
  pointer-events: auto;
  transition: opacity 0.5s;

  nav:hover & {
    opacity: 0.2;
  }

  &:hover {
    opacity: 1 !important;
  }

  ${media.mobile`
    ${font45};
  `}
`;
