function imgixUrl(url, parameters) {
  const imageUrl = new URL(url);

  for (const key in parameters) {
    imageUrl.searchParams.set(key, parameters[key]);
  }

  return decodeURIComponent(imageUrl.href);
}

export default imgixUrl;
