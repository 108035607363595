import propsToDom from 'core/helpers/propsToDom';
import React, { useRef } from 'react';
import styled from 'styled-components';
import ImgixOrSVG from './ImgixOrSVG';
import { clamp, useMotionTemplate, useSpring } from 'framer-motion';

const LinkImage = ({ url, width, height, children, ...others }) => {
  const handleClick = (event) => {
    event.preventDefault();
  };

  const ref = useRef(null);
  const physics = { damping: 14, stiffness: 80 };
  const x = useSpring(0, physics);
  const y = useSpring(0, physics);
  const scale = useSpring(0.8, physics);
  const opacity = useSpring(0, physics);
  const rotateX = useSpring(0, physics);
  const rotateY = useSpring(0, physics);
  const transform = useMotionTemplate`translate(${x}px, ${y}px) translate(-50%, -50%) scale(${scale}) rotateX(${rotateX}deg) rotateY(${rotateY}deg) translateZ(0)`;

  const mouse = useRef({
    prevX: 0,
    prevY: 0,
  });

  const handleEvent = (event) => {
    const bounds = ref.current.getBoundingClientRect();
    const isTouch = event.type.startsWith('touch');

    const allEvent = (isTouch ? event?.touches[0] : event) || { clientX: 0, clientY: 0 };
    const offsetX = allEvent.clientX - bounds.left;
    const offsetY = allEvent.clientY - bounds.top;

    switch (event.type) {
      case 'mouseenter':
      case 'touchstart':
        x.jump(offsetX);
        y.jump(offsetY);
        opacity.set(1);
        scale.set(1);
        if (isTouch) window.addEventListener('touchend', handleEvent);
        break;
      case 'mousemove':
      case 'touchmove':
        x.set(offsetX);
        y.set(offsetY);
        rotateX.set(clamp(-45, 45, (offsetY - mouse.current.prevY) * -2));
        rotateY.set(clamp(-45, 45, offsetX - mouse.current.prevX));
        mouse.current.prevX = offsetX;
        mouse.current.prevY = offsetY;
        break;
      case 'mouseleave':
      case 'touchend':
        if (isTouch) window.removeEventListener('touchend', handleEvent);
        opacity.set(0);
        scale.set(0.8);
        break;
    }
  };

  return (
    <a
      {...propsToDom(others)}
      href={url}
      target="_blank"
      rel="noreferrer"
      ref={ref}
      onClick={handleClick}
      onTouchStart={handleEvent}
      onTouchMove={handleEvent}
      onMouseEnter={handleEvent}
      onMouseMove={handleEvent}
      onMouseLeave={handleEvent}
    >
      {children}
      <ImgixOrSVG
        key={url}
        style={{
          width: `${width / 2}px`,
          opacity,
          transform,
        }}
        url={url}
        dimensions={{ width, height }}
        fluid={false}
        isNativeDimensions={true}
      />
    </a>
  );
};

export default styled(LinkImage)`
  position: relative;
  font-weight: 500;
  font-style: italic;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  user-select: none;
  perspective: 500px;

  span {
    pointer-events: none;
  }

  ${ImgixOrSVG} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -3;
    transform-origin: 50% 50%;
    pointer-events: none;
    user-select: none;
    will-change: transform;
  }
`;
