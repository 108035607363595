import { useState, useEffect, useCallback } from 'react';

const useIntersectionObserver = (
  ref,
  options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 1.0,
    once: false,
  }
) => {
  const [isIntersecting, setIntersecting] = useState(null);

  const handler = useCallback(
    ([entry], observer) => {
      const intersects = entry.isIntersecting || entry.intersectionRatio > options.threshold;

      setIntersecting(intersects);

      if (intersects && options.once === true) {
        observer.disconnect();
      }
    },
    [options.once, options.threshold]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handler, {
      rootMargin: options.rootMargin,
      root: options.root,
      threshold: options.threshold,
    });

    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, handler, options.rootMargin, options.root, options.threshold, options.once]);

  return isIntersecting;
};

export default useIntersectionObserver;
