import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from 'components/SEO';
import Page from 'components/Page';
import { getSlicesArray } from 'components/SliceZone';
import HeroProject from 'components/HeroProject';
import Scrollable from 'components/Scrollable';
import NextProject from 'components/NextProject';
import Icon from 'components/Icon';
import { fluidPx, sizeAsColPercent } from 'styles/utils';
import Link from 'components/Link';
import { useGlobalContext } from 'store/GlobalProvider';
import { motion, useTransform } from 'framer-motion';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Project = withPrismicPreview(({ data, pageContext }) => {
  const { isMobile, isPhoneOrTablet, scrollY, wh } = useGlobalContext();
  const closeY = useTransform(scrollY, (y) => Math.abs(Math.min(0, y - wh)));

  if (!data?.prismicProject?.data) return null;

  const {
    title,
    hero_background_color,
    page_background_color,
    page_text_color,
    background_color,
    accent_color,
    cover,
    logo,
    body,
    ...others
  } = data.prismicProject.data;

  const works = pageContext.siteData.works
    .filter(({ work }) => {
      const hasLink = !!work?.document?.url;
      const hasTitle = !!work?.document?.data?.title?.text;
      return hasLink && hasTitle;
    })
    .map(({ work }) => work.document);

  const currentWorkIndex = works.findIndex(({ uid }) => data.prismicProject.uid === uid);
  const nextWorkIndex =
    currentWorkIndex > -1
      ? currentWorkIndex + 1 >= works.length
        ? 0
        : currentWorkIndex + 1
      : null;

  return (
    <Module
      style={{
        '--hero-background-color': hero_background_color,
        '--page-background-color': page_background_color,
        '--page-text-color': page_text_color,
        '--project-background-color': background_color,
        '--project-accent-color': accent_color,
      }}
    >
      <SEO {...others} />
      {!isMobile && !isPhoneOrTablet && (
        <Close style={{ y: closeY }}>
          <Link
            to="/"
            exit={{
              length: 0.1,
            }}
            entry={{
              delay: 0.1,
              state: { anchor: 'work' },
            }}
          >
            <Icon id="close" />
          </Link>
        </Close>
      )}
      <Page key={data.prismicProject.id}>
        {[
          ...[<HeroProject key="cover" cover={cover} logo={logo} />],
          ...getSlicesArray({ slices: body }),
          ...(nextWorkIndex
            ? [<NextProject key={`next-${works[nextWorkIndex].id}`} work={works[nextWorkIndex]} />]
            : []),
        ]}
      </Page>
    </Module>
  );
});

const Module = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  background: var(--page-background-color, ${({ theme }) => theme.colors.white});
  color: var(--page-text-color, inherit);

  ${Scrollable} {
    background: var(--page-background-color, ${({ theme }) => theme.colors.white});
  }
`;

const Close = styled(motion.div)`
  position: fixed;
  top: ${({ theme }) => theme.spacing.s4};
  right: ${sizeAsColPercent(1)};
  z-index: 500;

  ${Link} {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.spacing.s2};
    width: ${({ theme }) => theme.spacing.s4};
    height: ${({ theme }) => theme.spacing.s4};
    background: ${({ theme }) => theme.colors.black};
    transition: transform 0.3s;
  }

  ${Icon} {
    font-size: ${fluidPx(13)};
    transition: transform 0.3s;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${Link} {
        transform: scale(1.1);
      }
      ${Icon} {
        transform: rotate(90deg) scale(${1 / 1.1});
      }
    }
  }
`;

export default Project;

export const pageQuery = graphql`
  query ProjectQuery($uid: String!, $lang: String!) {
    prismicProject(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      id
      uid
      data {
        seo_title
        seo_og_title
        seo_description
        seo_og_description
        seo_og_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        schemaorg {
          text
        }
        title {
          text
        }
        hero_background_color
        page_background_color
        page_text_color
        background_color
        accent_color
        cover {
          alt
          url
          dimensions {
            width
            height
          }
        }
        logo {
          alt
          url
          dimensions {
            width
            height
          }
        }
        body {
          ...ProjectMono
          ...ProjectDuo
          ...ProjectCarousel
          ...ProjectText
        }
      }
    }
  }
`;
