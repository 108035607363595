import styled, { css } from 'styled-components';

export const mainGrid = css`
  display: grid;
  grid-template-columns: repeat(24, minmax(10px, 1fr));
`;

const Grid = styled.div`
  ${mainGrid};
`;

export default Grid;
