import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import propsToDom from 'core/helpers/propsToDom';
import { buttonReset } from 'styles/others';
import { useGlobalContext } from 'store/GlobalProvider';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const LinkComponent = (
  {
    children,
    to,
    entry,
    exit,
    activeClassName,
    isBroken = false,
    type,
    state,
    Component = TransitionLink,
    ...others
  },
  ref
) => {
  const { setPaneId, isTabletPortrait } = useGlobalContext();

  if (type === 'page_about' && !isTabletPortrait) {
    const callback = others.onClick;

    Component = GatsbyLink;

    others.onClick = (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (callback instanceof Function) {
        callback(event);
      }

      setPaneId(type);
    };
  }

  if (!to || isBroken) {
    return <button {...propsToDom(others)}>{children}</button>;
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Component
        ref={ref}
        to={to}
        entry={entry}
        exit={exit}
        state={state}
        activeClassName={activeClassName}
        {...propsToDom(others)}
      >
        {children}
      </Component>
    );
  }
  return (
    <a
      ref={ref}
      href={to}
      {...propsToDom(others)}
      rel={others.target === '_blank' ? 'noopener' : null}
    >
      {children}
    </a>
  );
};

export default styled(forwardRef(LinkComponent))`
  ${buttonReset};
`;
