import styled from 'styled-components';
import { buttonReset } from 'styles/others';
import { fluidPx } from 'styles/utils';
import Icon from './Icon';

const Close = styled.button`
  ${buttonReset};
  width: ${({ theme }) => theme.spacing.s4};
  height: ${({ theme }) => theme.spacing.s4};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${fluidPx(14)};
  z-index: 3;
  transition: transform 0.3s;

  ${Icon} {
    transition: transform 0.3s;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.1);

      ${Icon} {
        transform: rotate(90deg) scale(${1 / 1.1});
      }
    }
  }
`;

export default Close;
