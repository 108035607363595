import beziers from 'core/beziers';
import propsToLink from 'core/helpers/propsToLink';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React, { useRef } from 'react';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import media from 'styles/media';
import { mainGrid } from './Grid';
import ImgixOrSVG from './ImgixOrSVG';
import Link from './Link';

const transitionContent = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.25,
};

const variants = {
  initial: {
    scaleY: 1,
  },
  entered: {
    scaleY: 1,
  },
  exit: {
    scaleY: 2,
    transition: {
      type: 'tween',
      duration: 1,
      ease: beziers.easeOutExpo,
      when: 'afterChildren',
    },
  },
};

const contentVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  entered: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.5,
      delay: 0.2,
      ease: beziers.easeOutExpo,
    },
  },
  exit: {
    opacity: 0,
    y: 0,
  },
};

const NextProject = ({ work, ...others }) => {
  const hasCover = !!work?.data?.cover?.url;
  const hasLogo = !!work?.data?.logo?.url;

  // ------------------------------------------------------------
  //    REFS & STATE
  // ------------------------------------------------------------
  const $element = useRef(null);
  const { scrollView } = useGlobalContext();
  const isIntersecting = useIntersectionObserver($element, {
    threshold: 0.0,
    root: scrollView,
    log: work.uid,
  });

  const { transitionStatus } = useTransitionState();
  const status =
    transitionStatus === 'exiting' || transitionStatus === 'exited'
      ? 'exit'
      : isIntersecting
      ? 'entered'
      : 'initial';

  // ------------------------------------------------------------
  //    ANIMATION
  // ------------------------------------------------------------
  const scaleY = useMotionValue(1);
  const scaleImg = useTransform(scaleY, (scale) => 1 / scale);
  const transformImg = useTransform(scaleY, (scale) => Math.abs(2 - scale) * 25 + '%');

  // ------------------------------------------------------------
  //    RENDERING
  // ------------------------------------------------------------
  return (
    <div {...others}>
      <Container variants={variants} style={{ scaleY }} animate={status}>
        <Link
          {...propsToLink(work)}
          onClick={() => {
            scrollView.scrollTo(0, scrollView.scrollHeight);
          }}
          exit={{
            length: 1.85,
          }}
          entry={{
            appearAfter: 1.75,
          }}
        >
          {hasCover && (
            <Background
              style={{ scaleY: scaleImg, y: transformImg }}
              src={work.data.cover.url}
              role="presentation"
            />
          )}
          <Content variants={contentVariants} transition={transitionContent}>
            <p ref={$element}>Next project</p>
            {hasLogo && <ImgixOrSVG {...work.data.logo} loading="eager" />}
          </Content>
        </Link>
      </Container>
    </div>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: 0 100%;
`;

const Background = styled(motion.img)`
  position: absolute;
  left: 0;
  bottom: 0;
  transform-origin: 0 100%;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const Content = styled(motion.div)`
  position: relative;
  z-index: 2;
  grid-row: 1 / -1;
  grid-column: 6 / span 14;
  align-self: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  p {
    margin-bottom: ${({ theme }) => theme.spacing.s3};
  }

  ${media.mobile`
    grid-column: 3 / span 20;

    p {
      margin-bottom: ${({ theme }) => theme.spacing.s2};
    }
  `}
`;

export default styled(NextProject)`
  position: relative;
  width: 100%;
  height: 50vh;
  z-index: 50;

  ${Link} {
    display: block;
    width: 100%;
    height: 100%;
    ${mainGrid}
  }
`;
