import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Grid from 'components/Grid';
import ImgixOrSVG, { getSizesAttr } from 'components/ImgixOrSVG';
import AppearingFlip from 'components/AppearingFlip';
import media from 'styles/media';

const DuoComponent = ({ primary, items, ...others }) => {
  const { background_color_override, accent_color_override } = primary;

  const filtered = items.filter(({ image, video }) => {
    const hasImage = !!image?.url;
    const hasVideo = !!video?.url;
    return hasImage || hasVideo;
  });

  return (
    <Grid {...others}>
      <SubGrid
        style={{
          '--project-background-color': background_color_override,
          '--project-accent-color': accent_color_override,
        }}
      >
        {filtered.map(({ image, video, video_mobile }, index) => {
          const hasImage = !!image?.url;
          const hasVideo = !!video?.url;
          const key = hasVideo ? video.url : image.url;

          const imageProps = hasImage && {
            ...image,
            isNativeDimensions: true,
            sizes: getSizesAttr({ cols: 6 }),
          };

          return (
            <Item key={key}>
              <AppearingFlip delay={index * 100}>
                {hasImage && <ImgixOrSVG {...imageProps} />}
                {hasVideo && <video src={video.url} />}
              </AppearingFlip>
            </Item>
          );
        })}
      </SubGrid>
    </Grid>
  );
};

const SubGrid = styled(Grid)`
  background: var(--project-background-color);
  padding-top: ${({ theme }) => theme.spacing.s12};
  padding-bottom: ${({ theme }) => theme.spacing.s12};
  grid-column: 3 / span 20;
  grid-template-columns: repeat(20, 1fr);

  ${media.mobile`
    padding-top: ${({ theme }) => theme.spacing.s5};
    padding-bottom: ${({ theme }) => theme.spacing.s5};
    grid-column: 1 / -1;
    grid-template-columns: repeat(24, 1fr);
  `}
`;

const Item = styled.div`
  grid-column: 4 / span 6;

  &:last-child {
    grid-column-start: 12;
  }

  ${media.mobile`
    grid-column: 2 / span 10;

    &:last-child {
      grid-column-start: 13;
    }
  `}
`;

export default styled(DuoComponent)`
  background: var(--page-background-color, inherit);
  padding-bottom: ${({ theme }) => theme.spacing.s15};

  ${media.mobile`
    padding-bottom: ${({ theme }) => theme.spacing.s4};
  `}
`;

export const query = graphql`
  fragment ProjectDuo on PrismicProjectDataBodyDuo {
    id
    slice_type
    slice_label
    primary {
      background_color_override
      accent_color_override
    }
    items {
      image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      video {
        url
      }
      video_mobile {
        url
      }
    }
  }
`;
