import { Application } from 'pixi.js';

class WebglManager {
  constructor({ $element }) {
    const app = new Application({
      view: $element,
      resizeTo: $element,
      antialias: true,
      powerPreference: 'high-performance',
      backgroundColor: 0x0f0076,
    });

    this.bind();
    this.app = app;
    this.app.stage.sortableChildren = true;
  }

  bind() {
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  add(container) {
    this.app.stage.addChild(container);
    this.app.start();
  }

  remove(container) {
    this.app.stage.removeChild(container);

    if (this.app.stage.children.length <= 0) {
      this.app.stop();
    }
  }

  destroy() {
    this.app.destroy(false, {
      children: true,
    });
  }
}

export default WebglManager;
