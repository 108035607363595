import { stiff } from 'constants/springs';
import propsToDom from 'core/helpers/propsToDom';
import { useScroll, useSpring } from 'framer-motion';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMount } from 'react-use';
import { useGlobalContext } from 'store/GlobalProvider';
import styled from 'styled-components';
import Scrollable from './Scrollable';

// ------------------------------------------------------------
//    MOBILE
// ------------------------------------------------------------
const PageMobile = ({ children, ...others }) => {
  const { setScrollY, scrollView, setScrollView, wh } = useGlobalContext();
  const $view = useRef(null);
  const { scrollY } = useScroll({ container: $view });
  const y = useSpring(scrollY, { ...stiff, mass: 0.1 });
  const { mount, entry, transitionStatus } = useTransitionState();
  const anchor = entry?.state?.anchor || false;

  const handleScrollTo = useCallback(
    ({ anchor, view }) => {
      try {
        const $element = scrollView.querySelector(`#${anchor}`);
        const $target = $element.offsetTop === 0 ? $element?.parentNode : $element;
        if ($target) {
          const top = $target.offsetTop;
          y.jump(top - 1);
          scrollView.scrollTo(0, top);
        }
      } catch (e) {
        // silent
      }
    },
    [scrollView, y]
  );

  useEffect(() => {
    if (anchor && mount) {
      const raf = requestAnimationFrame(() => handleScrollTo({ anchor }));

      return () => {
        cancelAnimationFrame(raf);
      };
    }
  }, [anchor, mount, handleScrollTo, transitionStatus]);

  useMount(() => {
    setScrollView($view.current);
    setScrollY(y);
  });

  return (
    <ScrollPageMobile {...propsToDom(others)} ref={$view} style={{ height: wh }}>
      {children}
    </ScrollPageMobile>
  );
};

const ScrollPageMobile = styled.div`
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  width: 100%;
  height: 100%;
`;

// ------------------------------------------------------------
//    DESKTOP
// ------------------------------------------------------------
const PageDesktop = ({ children, ...others }) => {
  const { scrollY, setScrollY, scrollView, setScrollView } = useGlobalContext();
  const { mount, entry, transitionStatus } = useTransitionState();
  const anchor = entry?.state?.anchor || false;

  const handleScrollTo = useCallback(
    ({ anchor, view }) => {
      try {
        const $element = scrollView.querySelector(`#${anchor}`);

        if ($element) {
          const top = $element.parentElement.offsetTop;
          scrollY.current = scrollY.prev = top - 1;
          scrollView.scrollTo(0, top);
        }
      } catch (e) {
        // silent
      }
    },
    [scrollView, scrollY]
  );

  useEffect(() => {
    if (anchor && mount) {
      const raf = requestAnimationFrame(() => handleScrollTo({ anchor }));

      return () => {
        cancelAnimationFrame(raf);
      };
    }
  }, [anchor, mount, handleScrollTo, transitionStatus]);

  const handleScrollMount = useCallback(
    ({ view, x, y }) => {
      setScrollView(view);
      setScrollY(y);
    },
    [setScrollView, setScrollY]
  );

  return (
    <ScrollPage {...propsToDom(others)} onMount={handleScrollMount}>
      {children}
    </ScrollPage>
  );
};

// ------------------------------------------------------------
//    PAGE
// ------------------------------------------------------------
const Page = (props) => {
  const { isMobile, isPhoneOrTablet } = useGlobalContext();
  return isMobile || isPhoneOrTablet ? <PageMobile {...props} /> : <PageDesktop {...props} />;
};

export default Page;

const ScrollPage = styled(Scrollable)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;
